import React from 'react';
import { Flex, Center, Box, Container, Heading, Stack, Text } from '@chakra-ui/react';
import { ClassNames } from '@emotion/react';


const AgendaItem = ({ time, description }) => {
  return (
    <Box borderWidth="1px" borderRadius="lg" p={5} mb={4} 
    width={{base: 'full', md: '100%'}} >
      <Text fontSize="lg" fontWeight="bold">
        {time}
      </Text>
      <Text mt={2}>{description}</Text>
    </Box>
  );
};

const Agenda = () => {
  return (
    <Box id="agenda" p={4}>
    
      {/* <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Agenda
        </Heading>
      </Stack> */}

      <Container maxW={'7xl'} mt={12} >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Heading paddingBottom={10} mb={2} fontSize="4xl">Agenda</Heading>
       </div>

        {/* <div style={{ display: 'flex' }}> */}
        {/* <Flex flexWrap="wrap" gridGap={12} justify="center"> */}

      

          <Flex flexWrap="wrap" justify="center">
          {/* morning */}

          <Box flex="1" width={{ base: '100%', md: '50%' }} margin="10px" border="0px solid black" padding="10px">
            <Heading paddingBottom={5} fontSize="2xl" textAlign="center">
              Morning Agenda
            </Heading>
            <AgendaItem
                time="10:00 AM - 10:15 AM"
                description="Opening Remarks, Keynote Speaker: Zach Gubner, Director of Edu. B@SB"
              />
              <AgendaItem
                time="10:15 AM - 10:45 PM"
                description="Jung Won Kim - Latern Finance CEO"
              />
              <AgendaItem
                time="10:45 AM - 11:30 AM"
                description={<span>Rapid Fireside Session <br /> <br /> <ul><li>Hans Liu - CEO of Riema Labs/Nubit</li> <li>Jay Freeman - CTO of Orchid</li><li>Matt Coburn - Starknet </li></ul></span>}
              />
              <AgendaItem
                time="11:30 AM - 12:00 PM"
                description={<span>Christopher Kruegel - Security Expert, UCSB <br /> Giovanni Vigna - Security Expert, UCSB</span>}
              />
          </Box>

          {/* afternoon */}

          <Box flex="1" width={{ base: '100%', md: '50%' }} margin="10px" border="0px solid black" padding="10px">
            <Heading paddingBottom={5} fontSize="2xl" textAlign="center">
              Afternoon Agenda
            </Heading>
            <AgendaItem
                time="12:00 PM - 12:30 PM"
                description="Lunch Break - Free food is provided 🌯🍔"
              />
              <AgendaItem
                time="1:00 PM - 1:30 PM"
                description="Coreum Workshop - ILP GA Classroom 2101"
              />
              <AgendaItem
                time="1:30 PM - 2:00 PM"
                description="Cameron Dennis - Head of Ecosystem, Banyan Collective"
              />
              <AgendaItem
                time="2:00 PM - 2:30 PM"
                description="Dahlia Malkhi - Blockchain/Consensus Expert, UCSB"
              />
              <AgendaItem
                time="2:30 PM - 3:00 PM"
                description="Spencer Bogart - Blockchain GP, Blockchain Capital"
              />
              <AgendaItem
                time="3:00 PM - 3:25 PM"
                description="Jasper Zhang - Co-Founder & CEO, Hyperbolic"
              />
              <AgendaItem
                time="3:25 PM"
                description="Closing Remarks and Networking"
              />
          </Box>
          
          </Flex>
          
      {/* </div> */}
       
      </Container>
    </Box>
  );
};

export default Agenda;
