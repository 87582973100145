import React from 'react';
import { Box, Container, Heading, Flex, Image } from '@chakra-ui/react';
import coreum from './coreum_logo-removebg-preview.png';
import hedera from './Hedera_Black_Logo.jpeg'

const Sponsors = () => {

  // Array of sponsor image URLs
  const sponsorImages = [
    coreum,
    'https://assets-global.website-files.com/62e9d2be8685a960f67c1271/64552faaad28a0aca1127043_Heirloom%20Black%20Lockup.svg',
    'https://near.org/_next/static/media/near-logo.1416a213.svg',
    hedera,
  ];

  return (
    <Box id="sponsors" p={4}>
      <Container maxW={'7xl'} mt={12}>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Heading mb={4}>Our Sponsors</Heading>
    </div>

        <Flex flexWrap="wrap" justify="center" gridGap={100}>
          {sponsorImages.map((imageUrl, index) => (
            <Image
              key={index}
              src={imageUrl}
              alt={`Sponsor ${index + 1}`}
              boxSize="225px"
              objectFit="contain"
            />
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default Sponsors;
