'use client'

import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Icon,
  Stack,
  Text,
  useColorModeValue,
} from '@chakra-ui/react'
import { ReactElement } from 'react'
import {
  FcAbout,
  FcAssistant,
  FcCollaboration,
  FcDonate,
  FcManager,
} from 'react-icons/fc'



const Card = ({ heading, description, imageUrl, href }: CardProps) => {
  return (
    <Box
      maxW={{ base: 'full', md: '250px' }}
      w={'full'}
      borderWidth="1px"
      borderRadius="lg"
      overflow="hidden"
      p={5}>
      
      <Stack align={'start'} spacing={2}>
        {/* Use Image component with imageUrl prop */}
        <Image
          src={imageUrl}
          alt={heading} // Set alt text
          w={"125px"}
          h={"125px"}
          rounded={'full'}
          objectFit="cover"
        />
        {/* <Flex
          w={16}
          h={16}
          align={'center'}
          justify={'center'}
          color={'white'}
          rounded={'full'}
          bg={useColorModeValue('gray.100', 'gray.700')}>
          {icon}
        </Flex> */}

        <Box mt={2}>
          <Heading size="md">{heading}</Heading>
          <Text mt={1} fontSize={'sm'}>
            {description}
          </Text>
        </Box>
        <a href={href} target="_blank" rel="noopener noreferrer">
          <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
            Learn more
          </Button>
        </a>
        {/* <Button variant={'link'} colorScheme={'blue'} size={'sm'}>
          Learn more
        </Button> */}
      </Stack>
    </Box>
  )
}

export default function Speakers() {
  return (
    <Box id="speakers" p={4} paddingTop={{ base: '0vh', md: '10vh' }}    >

      {/* <Stack spacing={4} as={Container} maxW={'3xl'} textAlign={'center'}>
        <Heading fontSize={{ base: '2xl', sm: '4xl' }} fontWeight={'bold'}>
          Speakers
        </Heading>
      </Stack> */}

      <Container maxW={'7xl'} mt={12}>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
       <Heading paddingBottom={10} mb={4}>Speakers</Heading>
      </div>

      {/* <Container maxW={{ base: 'full', md: '2705px' }} mt={12}> */}
        <Flex flexWrap="wrap" gridGap={12} justify="center">
          <Card
            heading={'Jay Freeman'}
            imageUrl={'https://pbs.twimg.com/profile_images/1434747464/square_400x400.png'}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'CTO, Orchid, Creator of Cydia'}
            href={'https://twitter.com/saurik?lang=en'}
          />
          <Card
            heading={'Prof. Dahlia Malkhi'}
            imageUrl={'https://cs.ucsb.edu/sites/default/files/styles/person_large_image/public/images/people/dahlia.jpg?itok=ta6GlxoG'} // Provide URL of the image
            icon={<Icon as={FcAssistant} w={10} h={10} />}
            description={'Distinguished Scientist of Chainlink Labs. Previously: CTO at Diem & Principal Researcher at VMWare & Microsoft '}
            href='https://cs.ucsb.edu/people/faculty/dahlia-malkhi'
          />
          <Card
            heading={'Jasper Zhang'}
            imageUrl={'https://pbs.twimg.com/profile_images/1788395893827497984/cu8YKGla_400x400.jpg'}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'Co-Founder and CEO of Hyperbolic, Previously at Citadel Securities, Ava Labs.'}
            href={'https://twitter.com/Zjasper666'}
          />
          <Card
            heading={'Prof. Christopher Kruegel'}
            imageUrl={'https://www.cs.ucsb.edu/sites/default/files/styles/person_large_image/public/2020-03/christopher-kruegel-largeImage-6-a-2080.jpg?itok=K8O2rEVD'} 
            icon={<Icon as={FcCollaboration} w={10} h={10} />}
            description={'Professor at UCSB, VP (NSBU) at VMware, Co-founder of Lastline'}
            href={'https://sites.cs.ucsb.edu/~chris/'}
          />
          <Card
            heading={'Prof. Giovanni Vigna'}
            imageUrl={'https://sites.cs.ucsb.edu/~vigna/files/giovanni_vigna.jpg'} 
            icon={<Icon as={FcDonate} w={10} h={10} />}
            description={'Professor at UCSB, Director of Threat, Intelligence at Broadcom, Co-founder of Lastline'}
            href={'https://sites.cs.ucsb.edu/~vigna/'}
          />
          <Card
            heading={'Cameron Dennis'}
            imageUrl={'https://media.licdn.com/dms/image/C5603AQEJx75_wNFGsQ/profile-displayphoto-shrink_800_800/0/1620738351952?e=1718236800&v=beta&t=HPac_1DFp1RaZT5CDcTsOZZdxou1SY5KQ5876ZzKK44'} 
            icon={<Icon as={FcManager} w={10} h={10} />}
            description={'CEO of Banyan Collective (NEAR)'}
            href={'https://www.linkedin.com/in/cameron-dennis-b93b25104/'}
          />
          <Card
            heading={'Spencer Bogart'}
            imageUrl={'https://assets-global.website-files.com/646f3aca43cb308828caa9f2/6499eacb3fadf2b4d1138a6d_Spencer.png'} 
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'General Partner at Blockchain Capital'}
            href={'https://www.blockchaincapital.com/team/spencer-bogart#:~:text=Spencer%20leads%20the%20firm\'s%20research,well%20as%20traditional%20equity%20opportunities.'}
          /> 
          <Card 
            heading={'Favio Velarde'}
            imageUrl={'https://www.coreum.com/assets/images/team/cards/favio.png'}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'Head of Growth & Partnership at Coreum'}
            href={'https://www.linkedin.com/in/faviovelarde/'}
          />
          <Card
            heading={'Jung Won Kim'}
            imageUrl={'https://media.licdn.com/dms/image/D5603AQHZyP405ySV2w/profile-displayphoto-shrink_400_400/0/1700723735129?e=1718236800&v=beta&t=2csXNrpEJ2U3mXKvj_EFnTsR1M76ihOUCWChlEhl9dc'}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'CEO of Lantern finance.'}
            href={'https://www.linkedin.com/in/jungwonkim/'}
          />
          <Card
            heading={'Hans Liu'}
            imageUrl={'https://applicantai.com/rails/active_storage/blobs/proxy/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBdFluIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--8fccc4b8caf36abc79c4c7930315b9499bc7ab53/nubit-twitter-logo.jpg'}
            icon={<Icon as={FcAbout} w={10} h={10} />}
            description={'Ceo of Riema Labs/Nubit.'}
            href={'#'}
          />
  
        </Flex>
      </Container>
    </Box>
  )
}