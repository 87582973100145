'use client'

import {
  Box,
  Flex,
  Avatar,
  HStack,
  Text,
  IconButton,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  MenuDivider,
  useDisclosure,
  useColorModeValue,
  Stack, 
  Image
} from '@chakra-ui/react'
import { HamburgerIcon, CloseIcon } from '@chakra-ui/icons'
import logo from './Blockchain-UCSB.png'
import { useState } from 'react'
import { Link as ScrollLink } from 'react-scroll';



const Links = ['Speakers', 'Agenda', 'Sponsors', ]
const NavLinks = ['css-h94677', 'chakra-container css-3vlr35', 'css-h94677']; 


const NavLink = (props) => {
  const { children, onClick } = props;

  return (
    <Box
      as="a"
      px={2}
      py={1}
      rounded={'md'}
      _hover={{
        textDecoration: 'none',
        bg: useColorModeValue('gray.200', 'gray.700'),
      }}
      cursor={'pointer'}
      onClick={onClick}
    >
      {children}
    </Box>
  );
};
export default function Navbar() {
  const [isOpen, setIsOpen] = useState(false);


  return (
    <>
      <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
      <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
        <HStack spacing={8} alignItems={'center'}>
          <Image src={logo} alt="Logo" width="auto" height="30px" />
          <HStack
            as={'nav'}
            spacing={4}
            display={{ base: 'none', md: 'flex' }}
            alignItems="center"
            justify="center"
          >
            {Links.map((link) => (
              <ScrollLink key={link} to={link.toLowerCase()} smooth={true} duration={500} style={{ textDecoration: 'none' }}>
                <NavLink>{link}</NavLink>
              </ScrollLink>
            ))}
          </HStack>
        </HStack>

          <Flex alignItems={'center'}>
            <Menu>
              <MenuButton
                as="a"  // Render as an anchor element
                href="https://events.heirloom.io/event/aed63be8-e0d7-42a1-936b-837dcb7d83f1"  // Specify the URL you want to navigate to
                rounded={'full'}
                variant={'link'}
                cursor={'pointer'}
                minW={0}>
                Register
              </MenuButton>
            </Menu>
          </Flex>

        </Flex>

    
      </Box>

    </>
  )
}