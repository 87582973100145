'use client'

import { Stack, Flex, Button, Text, VStack, useBreakpointValue, Link, Image } from '@chakra-ui/react'
import backgroundImage from './dalle.png'; // Import your image here

export default function BackgroundImage() {
  return (
    <Flex
      w={'full'}
      h={'100vh'}
      direction="column"
  
    >
      <Image
        src={backgroundImage}
        alt="Background Image"
        h={useBreakpointValue({ base: '80vh', md: '60vh' })} // Adjust the height as needed
        w="100%"
        objectFit="cover"
        zIndex="-1"
      />

      <Flex
        w={'full'}
        h={'100%'}
        justify={'center'}
        align={'center'}
        px={useBreakpointValue({ base: 4, md: 8 })}   
        paddingTop={{ base: '1vh', md: '4vh' }}    
      >
        <VStack
          w={'full'}
          spacing={4}
          textAlign={'center'}
          maxW={'2xl'}
        >
          <Text
            color={'black'}
            fontWeight={900}
            lineHeight={1.2}
            fontSize={useBreakpointValue({ base: '4xl', md: '5xl' })}
          >
            UCSB Blockchain Summit 2024
          </Text>
          <Text
            color={'black'}
            fontWeight={700}
            fontSize={useBreakpointValue({ base: '1xl', md: '2xl' })}
          >
            <b>Hosted by Blockchain at UCSB</b>
          </Text>
          <Text
            color={'black'}
            fontWeight={700}
            fontSize={useBreakpointValue({ base: '1xl', md: '2xl' })}
          >
            UCSB Campus | Interactive Learning Pavilion{' '}
            <b>Room 1203 and 2101</b> | May 11, 2024 | 10:00 AM - 3:30 PM
          </Text>
          <Stack
            direction={'row'}
            justify={'center'}
          >
            <Link href="https://events.heirloom.io/event/aed63be8-e0d7-42a1-936b-837dcb7d83f1" target="_blank">
              <Button
                as="a"
                bg={'blue.500'}
                rounded={'full'}
                color={'white'}
                _hover={{ bg: 'blue.700' }}>
                Register
              </Button>
            </Link>
            <Link href="mailto: team@blockchainucsb.com" target="_blank">
              <Button
                as="a"
                bg={'blue.300'}
                rounded={'full'}
                color={'white'}
                _hover={{ bg: 'blue.400' }}>
                Contact Us
              </Button>
            </Link>
          </Stack>
        </VStack>
      </Flex>
    </Flex>
  )
}
