import * as React from 'react'
import { Analytics } from "@vercel/analytics/react"

// 1. import `ChakraProvider` component
import { ChakraProvider } from '@chakra-ui/react'
import Navbar from './Navbar';
import Speakers from './Speakers';
import BackgroundImage from './BackgroundImage';
import Agenda from './Agenda';
import Sponsors from './Sponsor';

function App() {
  // 2. Wrap ChakraProvider at the root of your app
  return (
    <ChakraProvider>
      <Navbar></Navbar>
      <BackgroundImage></BackgroundImage>
      <Speakers></Speakers>
      <Agenda></Agenda>
      <Sponsors></Sponsors>
      <Analytics />
    </ChakraProvider>
  )
}
export default App;